<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar
        title="社区资讯"
      />
    </div> -->
    <!-- tab栏 -->
    <van-sticky>
      <van-tabs
        @click="onClickOnlyOne"
        color="#ed664e"
        title-active-color="#ed664e"
      >
        <van-tab name="全部" title="全部"> </van-tab>
        <van-tab
          v-for="item in TitleList"
          :key="item.AKId"
          :name="item.AKId"
          :title="item.Name"
        >
        </van-tab>
      </van-tabs>
      <!-- <van-tabs @click="topClick"
        v-else
        color="#ed664e"
        title-active-color="#ed664e">
        <van-tab v-for="item in TitleList"
          :key="item.AKId"
          :name="item.AKId"
          :title="item.Name">
          <van-tabs v-if="item.Children.length != '0'"
            v-model="activeName"
            @click="onClick"
            color="#ed664e"
            title-active-color="#ed664e">
            <van-tab v-for="item in item.Children"
              :key="item.AKId"
              :name="item.AKId"
              :title="item.Name">

            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs> -->
    </van-sticky>

    <div class="information">
      <!-- 全部 -->
      <div class="center borderClass" v-for="item in list" :key="item.index">
        <div @click="gotoUrl('/activity/detail/' + item.AId)">
          <div
            class="Content"
            style="
              border-bottom: 1px solid rgb(245 243 243);
              position: relative;
            "
          >
            <div
              style="display: inline-block; width: 32%"
              class="ContentA"
              ref="getHeight"
            >
              <span v-if="item.AImg">
                <img
                  :src="item.AImg"
                  alt=""
                  style="min-height: 80px; height: 100px"
                />
              </span>
              <span v-else>
                <img
                  src="http://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png"
                  alt=""
                  style="min-height: 80px; height: 100px"
                />
              </span>
            </div>
            <div class="ContentB">
              <div class="centerTitle van-ellipsis--l2">
                {{ item.Title }}
              </div>
              <div
                class="centertime van-multi-ellipsis--l2"
                style="margin: 30px 0px; font-size: 13px; color: #666666"
              >
                {{ item.Summary }}
              </div>
              <div class="centertime">
                <span>{{ item.IssTime | timeFilter }}</span>
                <!-- <span style="padding-left:10px;"> 阅读 {{item.ReadNum}}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOCode } from "@/utils/auth";
import { WxGetRecruitActivityPage, WxGetAKindList } from "@/api/RealInfo";
export default {
  data() {
    return {
      tkId: 0, //tab栏
      activeName: "",
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
        orgCode: undefined,
        akCate: 1,
      }, //查询传参
      hidden: true, //没有更多数据了
      TitleList: [],
    };
  },
  filters: {
    timeFilter(value) {
      if (value != undefined) {
        let gstime = value.replace("T", " ");
        // let gstime = value.substring(0, 10);
        return gstime;
      }
    },
  },
  methods: {
    // 资讯分类
    getPBActivityList: function () {
      WxGetAKindList({ cate: 1 }).then((res) => {
        this.TitleList = res.data.data;
        console.log(this.TitleList, "this.TitleList");
        // if (this.TitleList[0].Children != "0") {
        //   this.listfrom.AKId = 0;
        //   // this.listfrom.AKId = this.TitleList[0].Children[0].AKId
        // } else {
        //   this.listfrom.AKId = this.TitleList[0].AKId;
        // }
        this.getList();
      });
    },
    // 获取分页列表
    getList() {
      WxGetRecruitActivityPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].ThematicUrl == "") {
                  this.list[i].ThematicUrl =
                    "http://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    topClick: function (name, title) {
      for (let index = 0; index < this.TitleList.length; index++) {
        const element = this.TitleList[index];
        if (element.Children.length != "0") {
          if (element.AKId == name) {
            this.listfrom.AKId = element.Children[0].AKId;
            // this.listfrom.AKId = 0
            this.activeName = element.Children[0].AKId;
            this.getList();
          } else {
            this.listfrom.AKId = element.AKId;
            this.getList();
          }
        }
      }
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // tab点击切换事件
    onClick(name, title) {
      this.list = [];
      this.listfrom.akId = name;
      this.listfrom.page = 1;
      this.getList();
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      this.list = [];
      this.listfrom.akId = name;
      this.listfrom.page = 1;
      this.getList();
    },
  },
  mounted() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    if (getOCode() != undefined) {
      if (JSON.parse(getOCode())) {
        console.log(JSON.parse(getOCode()), "JSON.parse(getOCode())");
        this.listfrom.orgCode = JSON.parse(getOCode()).orgCode;
      } else {
        this.listfrom.orgCode = undefined;
      }
    }
    this.getPBActivityList();
  },
};
</script>
<style scoped>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}

.ContentB {
  display: inline-block;
  width: 62% !important;
  margin-left: 3% !important;
}

.bgcolor {
  position: absolute;
  /* background: linear-gradient(#f63e3b, #fff); */
  /* background: #f63e3b; */
  height: 150px;
  width: 100%;
}

.pageview {
  min-height: 100%;
  background: #fff;
}

.homebanner {
  width: 95%;
  margin: auto;
  margin-top: 15px;
}

.homebanner img {
  width: 100%;
  vertical-align: top;
  border-radius: 5px;
}

.wktime {
  padding: 0 20px;
}

.wktype {
  padding: 0 5px 5px;
}

.orginview .iconimg {
  width: 62%;
}

.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}

.wktype .iconimg {
  width: 60%;
}

.wktype .wktext {
  margin-top: 8px;
  font-size: 0.75rem;
}

.grouptitle {
  /* text-indent: 5px;
  font-size: 18px;
  color: #231815;
  font-weight: 700; */
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
}

.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}

.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fuwenben p {
  margin: 0 !important;
}

.moreCount {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-right: 4px;
}
.title {
  position: absolute;
  bottom: 25px;
  left: 20%;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
}
.homebanner .shou {
  width: 17.8%;
  position: absolute;
  top: 52%;
  right: 10.8%;
  transform: translateY(-50%);
}

.left,
.right {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  font-style: italic;
  color: #ffffff;
}

.left {
  font-weight: 100;
}

.ContentB .centerTitle {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231815;
}
.ContentB .centertime {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>